import { apiCallback } from 'api';
import { getFeatureFlags } from 'utils/feature-flags';

import queryWithoutDeliveryPassData from 'api/definitions/slotdays/slotdays-without-delivery-pass-data.graphql';
import query from 'api/definitions/slotdays/slotdays.graphql';
import { SlotDaysInput } from './types';

const definition = {
  graph: true,
  query: () => {
    // The two query files are only temporary until the slots.deliveryPassSlot can be queried from GraphQL in all environments.
    // Until this happens and the duplication removed, they should get kept in sync except for the presence of this extra field.
    const { deliveryPass_enableDeliveryPass: enableDeliveryPass } = getFeatureFlags();
    if (enableDeliveryPass) {
      return query;
    }

    return queryWithoutDeliveryPassData;
  },
  variables: ({
    branchId,
    slotType,
    customerOrderId,
    postcode,
    addressId,
    fromDate,
    size,
    slotGridType,
  }: SlotDaysInput) => ({
    slotDaysInput: {
      branchId,
      slotType,
      customerOrderId,
      postcode,
      addressId,
      fromDate,
      size,
      slotGridType,
    },
  }),
  queryString: { tag: 'slot-days' },
};

export default {
  getSlots: apiCallback(definition, 'post'),
};
